/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, Link, List, ListSubheader, Typography, makeStyles } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  User as UserIcon,
  AlignJustify as ChannelsIcon,
  Tv as ProvidersIcon,
  Rss as ChannelCreatorIcon

} from 'react-feather';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord';
import BarChartIcon from '@material-ui/icons/BarChart';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { useSelector, useDispatch } from 'src/store';
import { getChannels } from 'src/slices/report';
import { isFireside } from 'src/utils/urlUtils';

function renderNavItems({ items, pathname, depth = 0 }) {
  return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>;
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  const dispatch = useDispatch();
  const { stremiumUser } = useSelector(state => {
    return state.stremiumUser;
  });

  const { channels: userChannels } = useSelector(state => {
    return state.report.channelReport;
  });

  const sections = [];

  if(isFireside()) {
    sections.push({
      subheader: 'Fireside',
      items: [
        {
          title: 'Channel Creator',
          href: '/app/channelcreator',
          icon: ChannelCreatorIcon
        }
      ]
    });
  } else {
    sections.push({
        subheader: 'My Stremium',
        items: [
          {
            title: 'Account',
            href: '/app/account',
            icon: UserIcon
          },
          {
            title: 'Channels',
            icon: ChannelsIcon,
            href: '/app/channels'
          },
          {
            title: 'Providers',
            icon: ProvidersIcon,
            href: '/app/providers'
          }
        ]
      });

    sections.push({
      subheader: 'DVR',
      items: [
        {
          title: 'Recordings',
          href: '/app/dvr/recordings',
          icon: FiberManualRecordIcon
        },
        {
          title: 'Series',
          href: '/app/dvr/series',
          icon: FiberSmartRecordIcon
        }
      ]
    });

    sections.push({
      subheader: 'Create',
      items: [
        {
          title: 'Media Library',
          href: '/app/vod',
          icon: VideoLibraryIcon
        },
        {
          title: 'Channel Creator',
          href: '/app/channelcreator',
          icon: ChannelCreatorIcon
        },
        {
          title: 'Upload',
          href: '/app/channelcreator/add',
          icon: CloudUploadIcon
        }
      ]
    });
  }

  const reportSection = {
    subheader: 'Reports',
    items: [
      {
        title: 'Channel Stats',
        href: '/app/reports/channel-stats',
        icon: BarChartIcon
      }
    ]
  };
  useEffect(() => {
    dispatch(getChannels());
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp implementation="css">
          <Box p={2} display="flex" justifyContent="center">
            <Link href="https://www.stremium.com">
              <Logo />
            </Link>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
              {user.name}
            </Link>
            {false && (
              <Typography variant="body2" color="textSecondary">
                Your tier:{' '}
                <Link component={RouterLink} to="/app/subscription">
                  {stremiumUser.tier}
                </Link>
              </Typography>
            )}
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
          {userChannels !== null &&
            userChannels.length > 0 &&
            [reportSection].map(section => (
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp implementation="css">
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
