import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';
import axios from 'src/utils/axios';
import stremiumUserSlice from 'src/slices/stremiumUser';
import { useDispatch } from 'src/store';
import ReactPixel from 'react-facebook-pixel';
import Cookies from 'js-cookie';
import { getCurrentSubscriptions } from 'src/slices/currentSubscriptions';
import { useSelector } from 'src/store';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isAnonymous: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, isAnonymous, justRegistered, user } = action.payload;

      return {
        ...state,
        justRegistered,
        isAnonymous,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'USER_CHANGED': {
      const { isAuthenticated, isAnonymous, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isAnonymous,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithCustomToken: () => Promise.resolve(),
  signInWithFacebook: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  signInAnonymously: () => Promise.resolve(),
  sendPasswordResetEmail: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const location = useLocation();
  const history = useHistory();

  const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const sendPasswordResetEmail = (email) => {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  const signInWithCustomToken = (customToken) => {
    return firebase.auth().signInWithCustomToken(customToken);
  }

  const updatePassword = (password) => {
    const user = firebase.auth().currentUser;
    return user.updatePassword(password);
  };

  const signInWithFacebook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const signInAnonymously = () => {
    return firebase.auth().signInAnonymously();
  }

  const createUserWithEmailAndPassword = async (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  const mainStoreDispatch = useDispatch();
  
  useEffect(() => {
    let customToken = Cookies.get('token');
    Cookies.remove('token');
    if(customToken != null) {
      signInWithCustomToken(customToken);
    }

    const amazonToken = qs.parse(location.search, { ignoreQueryPrefix: true }).access_token;
    if(amazonToken != null) {
      axios.get('/externalToken?amazonAuthToken=' + encodeURIComponent(amazonToken)).then((response) => {
        signInWithCustomToken(response.data.token);
      }).catch((error) => {
        console.log(error);
      });
      dispatch({isInitialised: false});
    }

    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        if(user.isAnonymous) {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAnonymous: true,
              user: {
                id: user.uid,
                name: 'Anonymous'
              }
            }
          });
        } else {
          mainStoreDispatch(getCurrentSubscriptions());
          axios.get('/user?cardInfo=true').then((response) => {
            const advancedMatching = { em: user.email }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
            const options = {
              autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
              debug: false, // enable logs
            };

            mixpanel.identify(user.uid);
            mixpanel.people.set({ "dma": response.data.dma, "has_dvr_plan": response.data.hasDvrPlan, "is_trial_used": response.data.trialUsed, "plan_id": response.data.planId });

            ReactPixel.init('685901054913474', advancedMatching, options);
            var payloadExtras = {};
            if(response.status == 201) {
              ReactPixel.track('CompleteRegistration');
              mixpanel.track('account_created');
              payloadExtras = {justRegistered: true}
            }

            mainStoreDispatch(stremiumUserSlice.actions.setUser(response.data));
            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                ...payloadExtras,
                isAuthenticated: true,
                user: {
                  id: user.uid,
                  avatar: user.photoURL,
                  email: user.email,
                  name: user.displayName || user.email
                }
              }
            });
          });
        }
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  const { currentSubscriptions } = useSelector((state) => {
    return state.currentSubscriptions;
  });

  const amazonToken = qs.parse(location.search, { ignoreQueryPrefix: true }).access_token;
  if (!state.isInitialised || (state.isAuthenticated && currentSubscriptions === null)) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithCustomToken,
        signInWithFacebook,
        signInAnonymously,
        updatePassword,
        sendPasswordResetEmail,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
