import React from 'react';
import { Helmet } from 'react-helmet';

const ShareAShaleTag = () => {
  return (
    <Helmet>
      <script src="https://www.dwin1.com/19038.js" type="text/javascript" defer="defer"></script>
    </Helmet>
  );
};

export default ShareAShaleTag;
