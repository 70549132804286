import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    display: 'inline-flex',
    height: 70,
    transition: 'transform 300ms ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scaleX(1.05) scaleY(1.05)'
    }
  },
  icon: {
    objectFit: 'contain',
    width: '100%',
    height: '100%'
  }
}));

const AppStoreLinks = ({ title = 'Download the App to Your TV or Phone' }) => {
  const classes = useStyles();

  return (
    <>
      <Box textAlign="center">
        <Typography variant="h4">{title}</Typography>
      </Box>

      <Box mt={3} display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
        <a className={classes.iconWrapper} target="_blank" href="https://www.amazon.com/FitzyTV-Streaming-TV-Cloud-DVR/dp/B07DFW52P3">
          <img className={classes.icon} src="/static/images/stores/fire-tv.png" />
        </a>
        <a className={classes.iconWrapper} style={{ marginLeft: 8, marginRight: 8, height: 48 }} target="_blank" href="https://my.roku.com/account/add/Stremium">
          <img className={classes.icon} src="/static/images/stores/roku.png" />
        </a>
        <a className={classes.iconWrapper} target="_blank" href="https://play.google.com/store/apps/details?id=com.fitzytv.android">
          <img className={classes.icon} src="/static/images/stores/android-tv.png" />
        </a>
      </Box>
    </>
  );
};

export default AppStoreLinks;
