export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: 'AIzaSyBX1fAx_iQwjaPMn9iIaRtSoy-Xyp-eT6s',
  authDomain: 'fitzytv-chromecast.firebaseapp.com',
  databaseURL: 'https://fitzytv-chromecast.firebaseio.com',
  projectId: 'fitzytv-chromecast',
  storageBucket: 'fitzytv-chromecast.appspot.com',
  messagingSenderId: '48042235433',
  appId: '1:48042235433:web:2c2c8440766eb434ab2e38',
  measurementId: 'G-J98WKPG352'
};