import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stremiumUser: {}
};

const dvrPlanToString = (planId) => {
  switch(planId) {
    case "_25_HOUR":
    case "_25_HOUR_YEARLY":
      return "Premier 25";
    case "_50_HOUR":
    case "_50_HOUR_YEARLY":
      return "Premier 50";
    case "_75_HOUR":
    case "_75_HOUR_YEARLY":
      return "Premier 75";
    case "_100_HOUR":
    case "_100_HOUR_YEARLY":
      return "Premier 100";
    case "PREMIUM_25":
    case "PREMIUM":
      return "Legacy Premier 0";
    case null:
      return "Free";
    default:
      return "Unknown";
  }
};

const slice = createSlice({
  name: 'stremiumUser',
  initialState,
  reducers: {
    setUser(state, action) {
      state.stremiumUser = {
        ...action.payload,
        tier: dvrPlanToString(action.payload.planId)
      };
    },
    updateCreditCard(state, action) {
      const cardInfo = action.payload;
      state.stremiumUser.cardInfo = cardInfo;
    },
    updatePlanId(state, action) {
      const planId = action.payload;
      state.stremiumUser.planId = planId;
    },
    updatePendingCancellation(state, action) {
      const pendingCancellation = action.payload;
      state.stremiumUser.pendingCancellation = pendingCancellation;
    },
    updateCycleDate(state, action) {
      const cycleDate = action.payload;
      state.stremiumUser.cycleDate = cycleDate;
    }
  }
});

export const reducer = slice.reducer;

export const updateCreditCard = (cardInfo) => async (dispatch) => {
  dispatch(slice.actions.updateCreditCard(cardInfo));
};

export const updatePendingCancellation = (pendingCancellation) => async (dispatch) => {
  dispatch(slice.actions.updatePendingCancellation(pendingCancellation));
};

export const updatePlanId = (planId) => async (dispatch) => {
  dispatch(slice.actions.updatePlanId(planId));
};

export const updateCycleDate = (cycleDate) => async (dispatch) => {
  dispatch(slice.actions.updateCycleDate(cycleDate));
};

export default slice;
