import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

const GuestGuard = ({ children }) => {
  const { isAuthenticated, justRegistered } = useAuth();
  const location = useLocation();
  const query = location.search;
  const redirect = qs.parse(query, { ignoreQueryPrefix: true }).redirect;

  if (isAuthenticated) {
    return <Redirect to={justRegistered && (!redirect || !redirect.includes('/app/link')) ? '/app/link/subscribe-tips' : redirect ? redirect : '/app/account'} />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
