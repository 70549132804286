import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  channels: []
};

const slice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    getChannels(state, action) {
      state.channels = action.payload;
    },
    removeCustomChannel(state, action) {
      const channelId = action.payload;
      state.channels = state.channels.filter(element => element.id !== channelId);
    }
  }
});

export const reducer = slice.reducer;

export const getChannels = () => async (dispatch) => {
  const response = await axios.get('/programGuide?noGuideData=true');
  dispatch(slice.actions.getChannels(response.data));
};

export const removeCustomChannel = (channelId) => async (dispatch) => {
  axios.delete('/userChannel/' + channelId);
  dispatch(slice.actions.removeCustomChannel(channelId));
};

export default slice;
