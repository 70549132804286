import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import _ from 'lodash';
import uuid from 'react-uuid';

const initialState = {
  currentTab: 'videos',
  showPlaylistCreatorDrawer: false,
  showChannelCreatorDrawer: false,
  videos: null,
  videosUpload: {
    isEditMode: false,
    videos: []
  },
  selectedVideo: null,
  playlists: null,
  playlistEdit: {
    title: '',
    vodAssets: []
  },
  channels: [],
  channelEdit: {
    viewMode: 'List',
    selectedChannel: null,
    channelVodAssets: [],
    channelNewVodAssets: []
  },
  home: {
    selectedVod: null,
    navigations: null,
    navigationsCache: {}
  }
};

const slice = createSlice({
  name: 'vod',
  initialState,
  reducers: {
    // Vod
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setShowPlaylistCreatorDrawer(state, action) {
      state.showPlaylistCreatorDrawer = action.payload;
    },
    setShowChannelCreatorDrawer(state, action) {
      state.showChannelCreatorDrawer = action.payload;
    },

    getVodAssets(state, action) {
      state.videos = action.payload;
    },
    setSelectedVideo(state, action) {
      state.selectedVideo = action.payload;
    },
    initVideosUpload(state, action) {
      state.videosUpload = action.payload;
    },
    addVideo(state, action) {
      state.videosUpload.videos.unshift(action.payload);
    },
    removeVideo(state, action) {
      state.videosUpload.videos = state.videosUpload.videos.filter(n => n.id != action.payload.id);
    },
    deleteVideo(state, action) {
      state.videos = state.videos.filter(n => n.id != action.payload.id);
    },
    updateVideoField(state, action) {
      _.set(
        state.videosUpload.videos.find(n => n.id == action.payload.video.id),
        action.payload.field,
        action.payload.data
      );
    },

    // Playlist
    getPlaylists(state, action) {
      state.playlists = action.payload;
    },
    setPlaylistTitle(state, action) {
      state.playlistEdit.title = action.payload;
    },
    addPlaylistVideo(state, action) {
      state.playlistEdit.vodAssets.push(action.payload);
    },
    addPlaylistVideos(state, action) {
      state.playlistEdit.vodAssets.push(...action.payload);
    },
    replacePlaylistVideos(state, action) {
      state.playlistEdit.vodAssets = action.payload;
    },
    initPlaylistEdit(state, action) {
      state.playlistEdit = action.payload;
    },
    playlistSaved(state) {
      state.playlistEdit = {
        title: '',
        vodAssets: []
      };
    },
    deletePlaylist(state, action) {
      state.playlists = state.playlists.filter(n => n.id != action.payload.id);
    },
    // Channel
    getChannels(state, action) {
      state.channels = action.payload;
    },
    addChannel(state, action) {
      state.channels.push(action.payload);
    },
    deleteChannel(state, action) {
      state.channels = state.channels.filter(n => n.channelId != action.payload);
    },

    // Channel Creator
    toggleViewMode(state) {
      state.channelEdit.viewMode = state.channelEdit.viewMode === 'Calendar' ? 'List' : 'Calendar';
    },
    _calculateStartEnd(state, action) {
      const lastItem = _.last(state.channelEdit.channelVodAssets);
      const channelNewVodAssets = state.channelEdit.channelNewVodAssets;
      state.channelEdit.channelNewVodAssets = channelNewVodAssets.reduce((prevValues, curValue, index) => {
        if (index === 0) {
          prevValues.push({
            ...curValue,
            startTimestamp: lastItem ? lastItem.startTimestamp + lastItem.vodAsset.actualDurationMs : null
          });
        } else {
          prevValues.push({
            ...curValue,
            startTimestamp: prevValues[index - 1].startTimestamp ? prevValues[index - 1].startTimestamp + prevValues[index - 1].vodAsset.actualDurationMs : null
          });
        }

        return prevValues;
      }, []);
    },
    setSelectedChannel(state, action) {
      state.channelEdit.selectedChannel = action.payload;
    },
    initChannelEdit(state, action) {
      state.channelEdit.channelVodAssets = action.payload;
      state.channelEdit.channelNewVodAssets = [];
    },
    appendChannelVideo(state, action) {
      state.channelEdit.channelNewVodAssets.push(action.payload);
      slice.caseReducers._calculateStartEnd(state, action);
    },
    appendChannelVideos(state, action) {
      state.channelEdit.channelNewVodAssets.push(...action.payload);
      slice.caseReducers._calculateStartEnd(state, action);
    },
    // replaceChannelVideos(state, action) {
    //   state.channelEdit.channelNewVodAssets = action.payload;
    //   slice.caseReducers._calculateStartEnd(state, action);
    // },
    addChannelVideoIndex(state, action) {
      state.channelEdit.channelNewVodAssets.splice(action.payload.index, 0, action.payload.channelNewVodAsset);
    },
    addChannelVideosIndex(state, action) {
      state.channelEdit.channelNewVodAssets.splice(action.payload.index, 0, ...action.payload.channelNewVodAssets);
    },
    addChannelVideos(state, action) {
      state.channelEdit.channelNewVodAssets.push(...action.payload);
    },
    replaceChannelVideos(state, action) {
      state.channelEdit.channelNewVodAssets = action.payload;
    },
    channelPublished(state) {
      state.channelEdit = {
        selectedChannel: null,
        channelVodAssets: [],
        channelNewVodAssets: []
      };
    },
    setNavigations(state, action) {
      state.home.navigations = action.payload;
    },
    setNavigationsCache(state, action) {
      state.home.navigationsCache = action.payload;
    },
    setSelectedVod(state, action) {
      state.home.selectedVod = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const selectTotalUsedBytes = videos => {
  const total = (videos || []).map(n => n.originalFileSizeBytes).reduce((total, cur) => total + (cur || 0), 0);
  return total;
};

// Vod
export const setCurrentTab = tab => async dispatch => {
  dispatch(slice.actions.setCurrentTab(tab));
};

export const setShowPlaylistCreatorDrawer = isShow => async dispatch => {
  dispatch(slice.actions.setShowPlaylistCreatorDrawer(isShow));
  if (isShow) {
    dispatch(slice.actions.setShowChannelCreatorDrawer(false));
  }
};

export const setShowChannelCreatorDrawer = isShow => async dispatch => {
  dispatch(slice.actions.setShowChannelCreatorDrawer(isShow));
  if (isShow) {
    dispatch(slice.actions.setShowPlaylistCreatorDrawer(false));
  }
};

export const getVodAssets = () => async dispatch => {
  const response = await axios.get('/vodAsset');
  dispatch(slice.actions.getVodAssets(response.data));
};

export const setSelectedVideo = video => async dispatch => {
  dispatch(slice.actions.setSelectedVideo(video));
};

export const initVideosUpload = videosUpload => async dispatch => {
  dispatch(slice.actions.initVideosUpload(videosUpload));
};

export const addVideoFile = file => async dispatch => {
  const fileForm = new FormData();
  fileForm.append('file', file);
  dispatch(
    slice.actions.addVideo({
      id: uuid(),
      hlsUrl: '',
      episode: {
        title: '',
        description: '',
        primaryImageUrl: '',
        type: 0,
        episodeTitle: '',
        episodeNumber: 0,
        seasonNumber: 0
      },
      cuePoints: [],
      originalFilename: file.name,
      originalFileSizeBytes: file.size,
      fileForm: fileForm,
      isUrlUpload: false,
      status: null
    })
  );
};

export const addVideoUrl = videoUrl => async dispatch => {
  const { data } = await axios.post(`/upload/url`, {
    url: videoUrl
  });

  dispatch(
    slice.actions.addVideo({
      id: data.id,
      hlsUrl: '',
      episode: {
        title: '',
        description: '',
        primaryImageUrl: '',
        type: 0,
        episodeTitle: '',
        episodeNumber: 0,
        seasonNumber: 0
      },
      cuePoints: [],
      isUrlUpload: true,
      status: null
    })
  );
};

export const removeVideo = video => async dispatch => {
  dispatch(slice.actions.removeVideo(video));
};

export const deleteVideo = video => async dispatch => {
  dispatch(slice.actions.deleteVideo(video));
  await axios.delete(`/vodAsset/${video.id}`);
};

export const updateVideo = video => async () => {
  await axios.put(`/vodAsset/${video.id}`, video);
};

export const saveVideos = videos => async () => {
  const promises = [];
  videos.forEach(video => {
    promises.push(
      axios.put(`/vodAsset/${video.id}`, {
        originalFilename: video.originalFilename,
        originalFileSizeBytes: video.originalFileSizeBytes,
        hlsUrl: video.hlsUrl,
        episode: video.episode,
        cuePoints: video.cuePoints
      })
    );
  });

  await Promise.all(promises);
};

export const updateVideoField = (video, field, data) => async dispatch => {
  dispatch(slice.actions.updateVideoField({ video, field, data }));
};

export const cloneFromEpisode = (video, episode) => async dispatch => {
  dispatch(updateVideoField(video, 'episode.title', episode.title));
  dispatch(updateVideoField(video, 'episode.description', episode.description));
  dispatch(updateVideoField(video, 'episode.primaryImageUrl', episode.primaryImageUrl));
  dispatch(updateVideoField(video, 'episode.programID', episode.programID));
};

// Playlist
export const getPlaylists = () => async dispatch => {
  const response = await axios.get('/vodAssetPlaylist');
  dispatch(slice.actions.getPlaylists(response.data));
};

export const setPlaylistTitle = title => async dispatch => {
  dispatch(slice.actions.setPlaylistTitle(title));
};

export const addPlaylistVideo = video => async dispatch => {
  dispatch(slice.actions.addPlaylistVideo(video));
};

export const addPlaylistVideos = videos => async dispatch => {
  dispatch(slice.actions.addPlaylistVideos(videos));
};

export const replacePlaylistVideos = videos => async dispatch => {
  dispatch(slice.actions.replacePlaylistVideos(videos));
};

export const initPlaylistEdit = playlist => async dispatch => {
  dispatch(slice.actions.initPlaylistEdit(playlist));
};

export const savePlaylist = () => async (dispatch, getState) => {
  const { id, title, vodAssets } = getState().vod.playlistEdit;
  await axios.post('/vodAssetPlaylist', { id, title, vodAssets });

  dispatch(slice.actions.playlistSaved());
};

export const appendVideoToPlaylist = (video, playlist) => async dispatch => {
  dispatch(slice.actions.initPlaylistEdit(playlist));
  dispatch(addPlaylistVideo(video));
  return dispatch(savePlaylist());
};

export const deletePlaylist = playlist => async dispatch => {
  dispatch(slice.actions.deletePlaylist(playlist));
  await axios.delete(`/vodAssetPlaylist/${playlist.id}`);
};

// Channel
export const getChannels = () => async dispatch => {
  const response = await axios.get('/vodAssetChannel');
  dispatch(slice.actions.getChannels(response.data));
};

export const addChannel = channel => async dispatch => {
  const response = await axios.post('/vodAssetChannel', channel);
  dispatch(slice.actions.addChannel(response.data));
  return response.data;
};

export const updateChannel = channel => async dispatch => {
  const response = await axios.put(`/vodAssetChannel/${channel.channelId}`, channel);
  dispatch(getChannels());
  return response.data;
};

export const deleteChannel = channel => async (dispatch, getState) => {
  dispatch(slice.actions.deleteChannel(channel.channelId));
  if (getState().vod.channelEdit.selectedChannel?.channelId === channel.channelId) {
    dispatch(setShowChannelCreatorDrawer(false));
    dispatch(slice.actions.setSelectedChannel(null));
    dispatch(slice.actions.initChannelEdit([]));
  }
  await axios.delete(`/vodAssetChannel/${channel.channelId}`);
};

// Channel Creator
export const toggleViewMode = () => async dispatch => {
  dispatch(slice.actions.toggleViewMode());
};

export const setSelectedChannel = channel => async dispatch => {
  dispatch(slice.actions.setSelectedChannel(channel));
};

export const initChannelEdit = channelVodAssets => async dispatch => {
  dispatch(slice.actions.initChannelEdit(channelVodAssets));
};

export const getChannelVideos = channelId => async dispatch => {
  const response = await axios.get(`/vodAssetPlayout?channelId=${channelId}`);
  dispatch(slice.actions.initChannelEdit(response.data));
};

export const appendChannelVideo = vodAsset => async (dispatch, getState) => {
  dispatch(
    slice.actions.appendChannelVideo({
      channelId: getState().vod.channelEdit.selectedChannel.channelId,
      vodAsset
    })
  );
};

export const appendChannelVideos = vodAssets => async (dispatch, getState) => {
  const channelId = getState().vod.channelEdit.selectedChannel.channelId;
  const channelVodAssets = vodAssets.map(vodAsset => ({ channelId, vodAsset }));
  dispatch(slice.actions.appendChannelVideos(channelVodAssets));
};

const getSnap = (channelNewVodAssets, addStart, addEnd) => {
  const snapDuration = 30 * 60 * 1000;
  let snapStart = addStart;
  let lastEnd = 0;
  let index = 0;
  channelNewVodAssets.some((channelVodAsset, curIndex) => {
    const curStart = channelVodAsset.startTimestamp;
    const curEnd = channelVodAsset.startTimestamp + channelVodAsset.vodAsset.actualDurationMs;

    if (curIndex == channelNewVodAssets.length - 1 && curEnd <= addStart) {
      index = curIndex + 1;
      if (addStart - curEnd <= snapDuration) {
        snapStart = curEnd;
        return true;
      }

      return true;
    }

    if (lastEnd <= addStart && addEnd <= curStart) {
      index = curIndex;
      const prevDelta = addStart - lastEnd;
      const nextDelta = curStart - addEnd;
      if (prevDelta <= snapDuration) {
        snapStart = lastEnd;
        return true;
      }

      if (nextDelta <= snapDuration) {
        snapStart += curStart - addEnd;
        return true;
      }

      return true;
    }

    lastEnd = curStart + channelVodAsset.vodAsset.actualDurationMs;
  });

  return {
    index,
    snapStart: snapStart
  };
};

export const addChannelVideoIndex = (vodAsset, startTimestamp) => async (dispatch, getState) => {
  const { selectedChannel, channelNewVodAssets } = getState().vod.channelEdit;
  const { index, snapStart } = getSnap(channelNewVodAssets, startTimestamp, startTimestamp + vodAsset.actualDurationMs);

  dispatch(
    slice.actions.addChannelVideoIndex({
      index,
      channelNewVodAsset: {
        channelId: selectedChannel.channelId,
        startTimestamp: snapStart,
        vodAsset
      }
    })
  );
};

export const addChannelVideosIndex = (vodAssets, startTimestamp) => async (dispatch, getState) => {
  const { selectedChannel, channelNewVodAssets } = getState().vod.channelEdit;
  const duration = vodAssets.reduce((total, vodAsset) => total + vodAsset.actualDurationMs, 0);
  const { index, snapStart } = getSnap(channelNewVodAssets, startTimestamp, startTimestamp + duration);

  const snapChannelNewVodAssets = vodAssets.reduce((prevVodAssets, curValue, curIndex) => {
    if (curIndex === 0) {
      prevVodAssets.push({
        channelId: selectedChannel.channelId,
        vodAsset: curValue,
        startTimestamp: snapStart
      });
    } else {
      prevVodAssets.push({
        channelId: selectedChannel.channelId,
        vodAsset: curValue,
        startTimestamp: prevVodAssets[curIndex - 1].startTimestamp + prevVodAssets[curIndex - 1].vodAsset.actualDurationMs
      });
    }
    return prevVodAssets;
  }, []);

  dispatch(slice.actions.addChannelVideosIndex({ index, channelNewVodAssets: snapChannelNewVodAssets }));
};

export const replaceChannelVideos = vodAssets => async dispatch => {
  dispatch(slice.actions.replaceChannelVideos(vodAssets));
};

export const publishChannel = () => async (dispatch, getState) => {
  const channelEdit = getState().vod.channelEdit;
  const channelId = channelEdit.selectedChannel.channelId;
  const req = channelEdit.channelNewVodAssets.map(channelNewVodAsset => ({ channelId, vodAsset: channelNewVodAsset.vodAsset }));
  await axios.post('/vodAssetPlayout', req);

  dispatch(slice.actions.channelPublished());
};

// Navigation
export const gotoNavigationPath = path => async (dispatch, getState) => {
  let curNavigations = getState().vod.home.navigations;
  let curNavigationsCache = getState().vod.home.navigationsCache;

  let homeNavigation;
  if (!curNavigations) {
    const response = await axios.get('/navigation');
    const navigation = { id: null, ...response.data };
    homeNavigation = navigation;
  } else {
    homeNavigation = curNavigations[0];
  }

  const newNavigations = [homeNavigation];
  const newNavigationsCache = { ...curNavigationsCache };

  const ids = path ? path.split('/') : [];
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    let navigation = curNavigationsCache[id];
    if (!navigation) {
      const response = await axios.get(`/navigation/${id}`);
      navigation = { id, ...response.data };
    }

    newNavigations.push(navigation);
    newNavigationsCache[id] = navigation;
  }

  dispatch(slice.actions.setNavigations(newNavigations));
  dispatch(slice.actions.setNavigationsCache(newNavigationsCache));
  dispatch(setSelectedVod(newNavigations?.[newNavigations.length - 1].rows?.[0].items?.[0]));
};

export const setSelectedVod = vod => async dispatch => {
  dispatch(slice.actions.setSelectedVod(vod));
};

export default slice;
