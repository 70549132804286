import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, Checkbox, Divider, FormHelperText, Link, TextField, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import AmazonLoginButton from '../LoginView/AmazonLoginButton';
import LoadingButton from 'src/components/LoadingButton';

const useStyles = makeStyles(theme => ({
  root: {},
  facebookButton: {
    backgroundColor: '#3b5998',
    color: theme.palette.common.white
  },
  providerIcon: {
    marginRight: theme.spacing(2)
  },
  divider: {
    flexGrow: 1
  },
  dividerText: {
    margin: theme.spacing(2)
  },
  ctaButton: {
    backgroundColor: theme.palette.action.cta
  }
}));

const FirebaseAuthRegister = ({ className, ...rest }) => {
  const classes = useStyles();
  const { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithFacebook } = useAuth();
  const isMountedRef = useIsMountedRef();

  const handleFacebookClick = async () => {
    try {
      await signInWithFacebook();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <AmazonLoginButton />
      <Button className={classes.facebookButton} fullWidth onClick={handleFacebookClick} size="large" variant="contained">
        <img alt="Facebook" width="20" className={classes.providerIcon} src="/static/images/facebook.svg" />
        Register with Facebook
      </Button>
      <Box alignItems="center" display="flex" mt={2}>
        <Divider className={classes.divider} orientation="horizontal" />
        <Typography color="textSecondary" variant="body1" className={classes.dividerText}>
          OR
        </Typography>
        <Divider className={classes.divider} orientation="horizontal" />
      </Box>
      <Formik
        initialValues={{
          email: '',
          password: '',
          policy: true,
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          password: Yup.string()
            .min(7)
            .max(255)
            .required('Password is required'),
          policy: Yup.boolean().oneOf([true], 'This field must be checked')
        })}
        onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
          createUserWithEmailAndPassword(values.email, values.password)
            .then(() => {
              if (isMountedRef.current) {
                setStatus({ success: true });
              }
            })
            .catch(async err => {
              if ('auth/email-already-in-use' === err.code) {
                try {
                  await signInWithEmailAndPassword(values.email, values.password);
                  if (isMountedRef.current) {
                    setStatus({ success: true });
                  }
                } catch (signInErr) {
                  if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }
              } else {
                console.error(err);
                if (isMountedRef.current) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }
            });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate className={clsx(classes.root, className)} onSubmit={handleSubmit} {...rest}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <Box alignItems="center" display="flex" mt={2} ml={-1}>
              <Checkbox checked={values.policy} name="policy" onChange={handleChange} />
              <Typography variant="body2" color="textSecondary">
                I have read the{' '}
                <Link component="a" target="_blank" href="https://www.stremium.com/terms-of-service" color="secondary">
                  Terms and Conditions
                </Link>
              </Typography>
            </Box>
            {Boolean(touched.policy && errors.policy) && <FormHelperText error>{errors.policy}</FormHelperText>}
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box mt={2}>
              <LoadingButton className={classes.ctaButton} loading={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                Start Watching TV Now!
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

FirebaseAuthRegister.propTypes = {
  className: PropTypes.string
};

export default FirebaseAuthRegister;
