import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  makeStyles
} from '@material-ui/core';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const useStyles = makeStyles((theme) => ({
  root: {},
  amazonButton: {
    backgroundColor: '#FF9900',
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
  },
  providerIcon: {
    marginRight: theme.spacing(2),
    width: '20px'
  }
}));

const AmazonLoginButton = ({ className, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.onAmazonLoginReady = function() {
      window.amazon.Login.setClientId('amzn1.application-oa2-client.2e6eea5bbcd84376a4eb992d91831c9e');
    };
    (function(d) {
      var a = d.createElement('script'); a.type = 'text/javascript';
      a.async = true; a.id = 'amazon-login-sdk';
      a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
      d.getElementById('amazon-root').appendChild(a);
    })(document);
  }, []);
  const redirect = qs.parse(location.search, { ignoreQueryPrefix: true }).redirect;
  const handleAmazonClick = async () => {
    var options = {}
    options.scope = 'profile';
    options.scope_data = {
        'profile' : {'essential': false}
    };
    window.amazon.Login.authorize(options,
      redirect ? '/login?redirect=' + redirect : '/login');
  };

  return (
    <>
      <div id="amazon-root"></div>
      <Button
        id="amazonLogin"
        className={classes.amazonButton}
        fullWidth
        onClick={handleAmazonClick}
        size="large"
        variant="contained"
      >
        <img
          alt="Amazon"
          className={classes.providerIcon}
          src="/static/images/amazon.svg"
        />
        Log In with Amazon
      </Button>
    </>
  );
};

AmazonLoginButton.propTypes = {
  className: PropTypes.string,
};

export default AmazonLoginButton;
