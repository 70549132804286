import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import moment from 'moment';

const now = moment().valueOf();

const getRanges = (value, step, unit) => {
  return {
    preRange: {
      from:
        moment(now)
          .subtract(value + step, unit)
          .valueOf() - 1,
      to:
        moment(now)
          .subtract(value, unit)
          .valueOf() - 1
    },
    curRange: {
      from: moment(now)
        .subtract(value, unit)
        .valueOf(),
      to: now
    }
  };
};

const getPreviousRanges = (unit, last) => {
  return {
    preRange: {
      from: moment(last)
        .subtract(1, unit)
        .startOf(unit)
        .valueOf(),
      to: moment(last)
        .subtract(1, unit)
        .endOf(unit)
        .valueOf()
    },
    curRange: {
      from: moment(last)
        .startOf(unit)
        .valueOf(),
      to: last
    }
  };
};

const getToDayRanges = unit => {
  return {
    preRange: {
      from: moment(now)
        .subtract(1, unit)
        .startOf(unit)
        .valueOf(),
      to: moment(now)
        .subtract(1, unit)
        .valueOf()
    },
    curRange: {
      from: moment(now)
        .startOf(unit)
        .valueOf(),
      to: now
    }
  };
};

const past6Months = Array(6)
  .fill()
  .map((n, i) => {
    const curMonth = moment(now).subtract(i + 1, 'months');
    const month = curMonth.format('MMMM');
    return {
      ...getPreviousRanges('months', curMonth.endOf('month').valueOf()),
      value: month,
      text: month
    };
  });

const timeRanges = [
  {
    ...getRanges(7, 7, 'days'),
    value: '7 day',
    text: '7 day'
  },
  {
    ...getRanges(30, 30, 'days'),
    value: '30 day',
    text: '30 day'
  },
  ...past6Months,
  {
    ...getToDayRanges('months'),
    value: 'MTD',
    text: 'MTD'
  },
  {
    ...getToDayRanges('years'),
    value: 'YTD',
    text: 'YTD'
  }
];

const initialState = {
  channelReport: {
    selectedChannel: null,
    channels: null,
    selectedTimeRange: timeRanges[0],
    timeRanges: timeRanges,
    channelStats: null,
    preChannelStats: null
  }
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    getChannels(state, action) {
      state.channelReport.channels = action.payload;
    },
    setSelectedChannel(state, action) {
      state.channelReport.selectedChannel = action.payload;
    },
    setSelectedTimeRange(state, action) {
      state.channelReport.selectedTimeRange = action.payload;
    },
    getChannelStats(state, action) {
      state.channelReport.channelStats = action.payload;
      state.channelReport.preChannelStats = null;
    },
    getPreChannelStats(state, action) {
      state.channelReport.preChannelStats = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getChannels = () => async dispatch => {
  const response = await axios.get('/userChannel?publicOnly=true');
  dispatch(slice.actions.getChannels(response.data));
  return response.data;
};

export const setSelectedChannel = channel => async dispatch => {
  dispatch(slice.actions.setSelectedChannel(channel));
};

export const setSelectedTimeRange = timeRange => async dispatch => {
  dispatch(slice.actions.setSelectedTimeRange(timeRange));
};

export const getChannelStats = (selectedChannel, selectedTimeRange) => async dispatch => {
  // const channelId = selectedChannel.channelId;
  // const channelId = '3069fd30-77be-400a-8a26-9cb292f826d7';
  // const channelId = 'e6247374-472b-4fc5-9551-2da7b35b9a8a';
  // const timestampFrom = 1609462861000;
  // const timestampTo = 1634896766000;
  // const response = await axios.get(`/channelStats/${channelId}?timestampFrom=${timestampFrom}&timestampTo=${timestampTo}`);
  // dispatch(slice.actions.getChannelStats(response.data));

  const channelId = selectedChannel.channelId;
  const { curRange, preRange } = selectedTimeRange;
  const curResponse = await axios.get(`/channelStats/${channelId}?timestampFrom=${curRange.from}&timestampTo=${curRange.to}`);
  dispatch(slice.actions.getChannelStats(curResponse.data));
  axios
    .get(`/channelStats/${channelId}?timestampFrom=${preRange.from}&timestampTo=${preRange.to}`)
    .then(preResponse => dispatch(slice.actions.getPreChannelStats(preResponse.data)));
};

export default slice;
