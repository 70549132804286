import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, Divider, FormHelperText, TextField, Typography, makeStyles } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import AmazonLoginButton from './AmazonLoginButton';
import LoadingButton from 'src/components/LoadingButton';

const useStyles = makeStyles(theme => ({
  root: {},
  facebookButton: {
    backgroundColor: '#3b5998',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2)
  },
  providerIcon: {
    marginRight: theme.spacing(2),
    width: '20px'
  },
  divider: {
    flexGrow: 1
  },
  dividerText: {
    margin: theme.spacing(2)
  }
}));

const FirebaseAuthLogin = ({ className, ...rest }) => {
  const classes = useStyles();
  const { signInWithEmailAndPassword, signInWithFacebook } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const handleFacebookClick = async () => {
    try {
      await signInWithFacebook();
    } catch (err) {
      console.error(err);

      enqueueSnackbar(err.message, {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <AmazonLoginButton />
      <Button className={classes.facebookButton} fullWidth id="facebookLogin" onClick={handleFacebookClick} size="large" variant="contained">
        <img alt="Facebook" className={classes.providerIcon} src="/static/images/facebook.svg" />
        Sign in with Facebook
      </Button>
      <Box alignItems="center" display="flex" mt={2}>
        <Divider className={classes.divider} orientation="horizontal" />
        <Typography color="textSecondary" variant="body1" className={classes.dividerText}>
          OR
        </Typography>
        <Divider className={classes.divider} orientation="horizontal" />
      </Box>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          password: Yup.string()
            .max(255)
            .required('Password is required')
        })}
        onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
          signInWithEmailAndPassword(values.email, values.password)
            .then(() => {
              if (isMountedRef.current) {
                setStatus({ success: true });
              }
            })
            .catch(err => {
              console.log(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box mt={2}>
              <LoadingButton id="emailLogin" color="secondary" loading={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                Log In
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

FirebaseAuthLogin.propTypes = {
  className: PropTypes.string
};

export default FirebaseAuthLogin;
