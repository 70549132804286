import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  currentProviders: []
};

const slice = createSlice({
  name: 'currentProviders',
  initialState,
  reducers: {
    getCurrentProviders(state, action) {
      state.currentProviders = action.payload;
    },
    addCurrentProvider(state, action) {
      const providerId = action.payload;
      state.currentProviders = [...state.currentProviders, providerId];
    },
    removeCurrentProvider(state, action) {
      const providerId = action.payload;
      state.currentProviders = state.currentProviders.filter(element => element !== providerId);
    }
  }
});

export const reducer = slice.reducer;

export const getCurrentProviders = () => async (dispatch) => {
  const response = await axios.get('/session');
  dispatch(slice.actions.getCurrentProviders(response.data));
};

export const addCurrentProvider = (providerId) => async (dispatch) => {
  dispatch(slice.actions.addToFavorites(providerId));
};

export const removeCurrentProvider = (providerId) => async (dispatch) => {
  axios.delete('/session?provider=' + providerId);
  dispatch(slice.actions.removeCurrentProvider(providerId));
};

export default slice;
