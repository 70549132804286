import React from 'react';
import { isFireside } from 'src/utils/urlUtils';

const Logo = React.forwardRef((props, ref) => {
  return isFireside() ? 
    <img alt="Logo" style={{ height: props.height ? props.height : '72px', padding: '10px' }} src="/fireside-icon.png" {...props} ref={ref} /> :
    <img alt="Logo" style={{ height: props.height ? props.height : '72px', padding: '10px' }} src="/static/logo.png" {...props} ref={ref} />;

});

export default Logo;
