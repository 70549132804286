import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

const LoadingButton = ({ loading, children, ...rest }) => {
  return (
    <Button disabled={loading} {...rest}>
      {loading ? (
        <>
          &nbsp; <CircularProgress size={24} /> &nbsp;
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default LoadingButton;
