import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'src/store';
import { useSnackbar } from 'notistack';


const SubscriptionGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const redirect = location.pathname + (location.search !== "" ? encodeURIComponent(location.search) : "");

  const { stremiumUser } = useSelector(state => {
    return state.stremiumUser;
  });

  if (!isAuthenticated) {
    const redirectUrl = redirect ? `/register?redirect=${redirect}` : '/register/';
    return <Redirect to={redirectUrl} />;
  }

  if (!stremiumUser.hasDvrPlan) {
    enqueueSnackbar('You must have a Basic subscription. Please select a plan.', { variant: 'error' });

    const redirectUrl = '/app/subscription/dvr';
    return <Redirect to={redirectUrl} />;
  }

  return (
    <>
      {children}
    </>
  );
};

SubscriptionGuard.propTypes = {
  children: PropTypes.node
};

export default SubscriptionGuard;
