import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';

const FiresideBanner = () => {
  return (
      <Box style={{ background: 'black' }} p={4}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Link href="https://www.firesidechat.com">
            <img height={62} alt="fireside-icon" src="/fireside-icon.png" />
          </Link>
          <Typography style={{ margin: '0 1.5rem' }} color="textPrimary">
            +
          </Typography>
          <img height={62} alt="stremium-icon" src="/stremium-icon.png" />
        </Box>
        <Box display="flex" flexDirection="column" textAlign="center" mt={3}>
          <Typography color="textPrimary" variant="h4">
            Fireside + Stremium have joined forces to Pioneer a New Era of Media & Entertainment
          </Typography>
          <Typography color="textPrimary" variant="h4">
            The world's first interactive streaming platform just got bigger! Fireside is coming to a TV near you!
          </Typography>
        </Box>
      </Box>
  );
};

export default FiresideBanner;
