import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as channelReducer } from 'src/slices/channel';
import { reducer as favoriteChannelsReducer } from 'src/slices/favoriteChannels';
import { reducer as providersReducer } from 'src/slices/provider';
import { reducer as currentProvidersReducer } from 'src/slices/currentProviders';
import { reducer as currentSubscriptionsReducer } from 'src/slices/currentSubscriptions';

import { reducer as stremiumUserReducer } from 'src/slices/stremiumUser';
import { reducer as dvrReducer } from 'src/slices/dvr';
import { reducer as vodReducer } from 'src/slices/vod';
import { reducer as cartReducer } from 'src/slices/cart';
import { reducer as reportReducer } from 'src/slices/report';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  channels: channelReducer,
  dvr: dvrReducer,
  favoriteChannels: favoriteChannelsReducer,
  providers: providersReducer,
  currentProviders: currentProvidersReducer,
  stremiumUser: stremiumUserReducer,
  vod: vodReducer,
  cart: cartReducer,
  currentSubscriptions: currentSubscriptionsReducer,
  report: reportReducer
});

export default rootReducer;
