import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: {}
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, action) {
      const plan = action.payload;
      state.cart = {...state.cart, ...plan};
    }
  }
});

export const reducer = slice.reducer;

export const addToCart = (plan) => async (dispatch) => {
  dispatch(slice.actions.addToCart(plan));
};

export default slice;
