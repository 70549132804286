import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  providers: []
};

const slice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    getProviders(state, action) {
      state.providers = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getProviders = () => async (dispatch) => {
  const response = await axios.get('/providers');
  dispatch(slice.actions.getProviders(response.data));
};

export default slice;
