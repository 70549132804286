import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Chip, Container, Divider, Link, Tooltip, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import Auth0Login from './Auth0Login';
import FirebaseAuthLogin from './FirebaseAuthLogin';
import JWTLogin from './JWTLogin';
import { useLocation } from 'react-router-dom';
import FiresideBanner from '../components/FiresideBanner';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  banner: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bannerChip: {
    marginRight: theme.spacing(2)
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardContainer: {
    paddingBottom: 0,
    paddingTop: 20
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400
  },
  linkContent: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%'
    }
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const { method } = useAuth();
  const location = useLocation();
  const query = location.search;

  return (
    <Page className={classes.root} title="Stremium - Login">
      <FiresideBanner />
      <Container className={classes.cardContainer} maxWidth="sm">
        <Box mb={2} display="flex" justifyContent="center">
          <Link href="https://www.stremium.com">
            <Logo height="144px" />
          </Link>
        </Box>
        <Card>
          <CardContent className={classes.cardContent}>
            <Box alignItems="center" justifyContent="space-between" mb={3}>
              <div>
                <Typography color="textPrimary" gutterBottom variant="h2">
                  Sign in
                </Typography>
              </div>
            </Box>
            <Box flexGrow={1} mt={3}>
              {method === 'Auth0' && <Auth0Login />}
              {method === 'FirebaseAuth' && <FirebaseAuthLogin />}
              {method === 'JWT' && <JWTLogin />}
            </Box>
            <Box my={3}>
              <Divider />
            </Box>
            <Box className={classes.linkContent}>
              <Box style={{ width: '50%' }}>
                <Link component={RouterLink} to={'/register' + (query ? query : '')} variant="body2" color="textSecondary">
                  Create new account
                </Link>
              </Box>
              <Box style={{ width: '50%' }} align="right">
                <Link component={RouterLink} to="/forgotPassword" variant="body2" color="textSecondary">
                  Forgot Password?
                </Link>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default LoginView;
