import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  recordings: null,
  seriesRecordings: null
};

const slice = createSlice({
  name: 'dvr',
  initialState,
  reducers: {
    getRecordings(state, action) {
      state.recordings = action.payload;
    },
    getSeriesRecordings(state, action) {
      state.seriesRecordings = action.payload;
    },
    deleteRecording(state, action) {
      const recordingId = action.payload;
      state.recordings = state.recordings.filter(element => element.id !== recordingId);
    },
    deleteSeriesRecording(state, action) {
      const seriesId = action.payload;
      state.seriesRecordings = state.seriesRecordings.filter(element => element.id !== seriesId);
    },
    addSeriesRecording(state, action) {
      const series = action.payload;
      state.seriesRecordings = [...state.seriesRecordings, series];
    }
  }
});

export const reducer = slice.reducer;

export const getRecordings = () => async (dispatch) => {
  const response = await axios.get('/recording');
  dispatch(slice.actions.getRecordings(response.data));
};

export const getSeriesRecordings = () => async (dispatch) => {
  const response = await axios.get('/series');
  dispatch(slice.actions.getSeriesRecordings(response.data));
};

export const deleteSeriesRecording = (seriesId) => async (dispatch) => {
  axios.delete('/series/' + seriesId);
  dispatch(slice.actions.deleteSeriesRecording(seriesId));
};

export const addSeriesRecording = (series) => async (dispatch) => {
  dispatch(slice.actions.addSeriesRecording(series));
};

export const deleteRecording = (recordingId) => async (dispatch) => {
  axios.delete('/recording/' + recordingId);
  dispatch(slice.actions.deleteRecording(recordingId));
};

export default slice;
