import axios from 'axios';
import firebase from 'src/lib/firebase';
import Cookies from 'js-cookie';
import uuid from 'react-uuid';

const axiosInstance = axios.create({baseURL:"https://api.stremium.com"});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && {status: error.response.status, ...error.response.data}) || 'Something went wrong')
);

axiosInstance.defaults.withCredentials = true;
axiosInstance.interceptors.request.use(async (config) => {
  const user = firebase.auth().currentUser;
  try {
    if(user) {
      const token = await user.getIdToken();
      config.headers.Authorization = token;

      let deviceId = Cookies.get('deviceId');
      if(deviceId == null) {
        deviceId = uuid();
      }
      Cookies.set('deviceId', deviceId);
      config.headers.common['X-FITZYTV-DEVICE-ID'] = deviceId;
    }
  } catch(err) {
    console.log(err);
  }

  return config;
});

export default axiosInstance;
