import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { Box, Dialog, makeStyles, Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import TopBar from 'src/layouts/DashboardLayout/TopBar';
import DashboardLayout from 'src/layouts/DashboardLayout';
import RegisterView from 'src/views/auth/RegisterView';
import AppStoreLinks from './AppStoreLinks';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: 64,
    height: '100vh',
    background: `${theme.palette.background.default}`
  },
  closeWrapper: {
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

const AnonymousGuard = ({ children }) => {
  const classes = useStyles();

  const { isAuthenticated } = useAuth();
  const [downloadAppOpen, setDownloadAppOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);

  useEffect(() => {
    const downloadAppTimeoutId = setTimeout(() => {
      if (window.location.hash.includes('#register')) return;
      if (document.fullscreenElement && document.exitFullscreen) document.exitFullscreen();
      setDownloadAppOpen(true);
    }, 1 * 60 * 1000);

    const registerTimeoutId = setTimeout(() => {
      if (window.location.hash.includes('#register')) return;
      if (document.fullscreenElement && document.exitFullscreen) document.exitFullscreen();
      setDownloadAppOpen(false);
      setRegisterOpen(true);
    }, 2 * 60 * 1000);

    return () => {
      clearTimeout(downloadAppTimeoutId);
      clearTimeout(registerTimeoutId);
    };
  }, []);

  useEffect(() => {
    window.location.hash = '';
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return <DashboardLayout>{children}</DashboardLayout>;
  }

  return (
    <>
      <Dialog open={downloadAppOpen} fullWidth maxWidth="sm" scroll="body">
        <Box m={2.5} mt={3}>
          <IconButton className={classes.closeWrapper} onClick={() => setDownloadAppOpen(false)}>
            <CloseIcon />
          </IconButton>
          <AppStoreLinks />
        </Box>
      </Dialog>
      <Dialog open={registerOpen} fullWidth maxWidth="sm" scroll="body">
        <RegisterView message="Register for Stremium to Continue Watching" />
      </Dialog>
      <TopBar />
      <Box className={classes.wrapper}>{children}</Box>
    </>
  );
};

AnonymousGuard.propTypes = {
  children: PropTypes.node
};

export default AnonymousGuard;
