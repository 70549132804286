import React from 'react';
import { Helmet } from 'react-helmet';
import './Churnkey.css';

const Churnkey = () => {
  return (
    <Helmet>
      <script>
        {`
          !function(){  
            if (!window.churnkey || !window.churnkey.created) {
              window.churnkey = { created: true };
              const a = document.createElement('script');
              a.src = 'https://assets.churnkey.co/js/app.js?appId=YOUR_APP_ID';
              a.async = true;
              const b = document.getElementsByTagName('script')[0];
              b.parentNode.insertBefore(a, b);
            }
          }();
        `}
      </script>
    </Helmet>
  );
};

export default Churnkey;
