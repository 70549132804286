import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { useLocation } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  console.log("Version 1.02");

  const redirect = location.pathname + (location.search !== "" ? encodeURIComponent(location.search) : "");

  if (!isAuthenticated) {
    const redirectUrl = redirect ? `/register?redirect=${redirect}` : '/register/';
    return <Redirect to={redirectUrl} />;
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
