import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  currentSubscriptions: null
};

const slice = createSlice({
  name: 'currentSubscriptions',
  initialState,
  reducers: {
    getCurrentSubscriptions(state, action) {
      state.currentSubscriptions = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getCurrentSubscriptions = () => async (dispatch) => {
  const response = await axios.get('/subscription');
  dispatch(slice.actions.getCurrentSubscriptions(response.data));
};

export default slice;
