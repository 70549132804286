import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  favoriteChannels: []
};

const slice = createSlice({
  name: 'favoriteChannels',
  initialState,
  reducers: {
    getFavoriteChannels(state, action) {
      state.favoriteChannels = action.payload;
    },
    addToFavorites(state, action) {
      const channelId = action.payload;
      state.favoriteChannels = [...state.favoriteChannels, channelId];
    },
    removeFromFavorites(state, action) {
      const channelId = action.payload;
      state.favoriteChannels = state.favoriteChannels.filter(element => element !== channelId);
    }
  }
});

export const reducer = slice.reducer;

export const getFavoriteChannels = () => async (dispatch) => {
  const response = await axios.get('/favoriteChannels');
  dispatch(slice.actions.getFavoriteChannels(response.data));
};

export const addToFavorites = (channelId) => async (dispatch) => {
  console.log("Adding to favorites: " + channelId);
  axios.put('/favoriteChannels?channelId=' + channelId).then(() => {
    console.log("Success adding " + channelId + " to favorites");
  });
  dispatch(slice.actions.addToFavorites(channelId));
};

export const removeFromFavorites = (channelId) => async (dispatch) => {
  axios.delete('/favoriteChannels?channelId=' + channelId);
  dispatch(slice.actions.removeFromFavorites(channelId));
};



export default slice;
