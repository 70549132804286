import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import TemporaryAuthGuard from './components/TemporaryAuthGuard';
import AnonymousGuard from './components/AnonymousGuard';
import SubscriptionGuard from './components/SubscriptionGuard';
import StorageGuard from './components/StorageGuard';
import { isFireside } from './utils/urlUtils';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgotPassword',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: TemporaryAuthGuard,
    path: '/app/player',
    component: lazy(() => import('src/views/player'))
  },
  {
    exact: true,
    guard: AnonymousGuard,
    path: '/app/channels/:channelId?',
    component: lazy(() => import('src/views/epg/EpgPlayerView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/channel/add',
        component: lazy(() => import('src/views/channel/ChannelAddView'))
      },
      {
        exact: true,
        path: '/app/dvr/recordings',
        guard: SubscriptionGuard,
        component: lazy(() => import('src/views/dvr/RecordingListView'))
      },
      {
        exact: true,
        path: '/app/dvr/series',
        guard: SubscriptionGuard,
        component: lazy(() => import('src/views/dvr/SeriesListView'))
      },
      {
        exact: true,
        path: '/app/subscription',
        component: lazy(() => import('src/views/pricing/PlanSelectView'))
      },
      {
        exact: true,
        path: '/app/subscription/plan',
        component: lazy(() => import('src/views/pricing/PlanSelectView'))
      },
      {
        exact: true,
        path: '/app/subscription/dvr',
        component: lazy(() => import('src/views/pricing/CustomPackageView'))
      },
      {
        exact: true,
        path: '/app/link',
        component: lazy(() => import('src/views/devices/LinkTV'))
      },
      {
        exact: true,
        path: '/app/link/connect-providers',
        component: lazy(() => import('src/views/devices/LinkTV/ConnectProviders'))
      },
      {
        exact: true,
        path: '/app/link/connect-providers/add',
        component: lazy(() => import('src/views/devices/LinkTV/AddProvider'))
      },
      {
        exact: true,
        path: '/app/link/series-suggestion',
        component: lazy(() => import('src/views/devices/LinkTV/SeriesSuggestion'))
      },
      {
        exact: true,
        path: '/app/link/subscribe-tips',
        component: lazy(() => import('src/views/devices/LinkTV/SubscribeTips'))
      },
      {
        exact: true,
        path: '/app/link/success',
        component: lazy(() => import('src/views/devices/LinkTV/Success'))
      },
      {
        exact: true,
        path: '/app/providers',
        component: lazy(() => import('src/views/providers/ProviderListView'))
      },
      {
        exact: true,
        path: '/app/providers/add',
        component: lazy(() => import('src/views/providers/ProviderAddView'))
      },
      {
        exact: true,
        path: '/app/reports/channel-stats',
        component: lazy(() => import('src/views/reports/ChannelStatsView'))
      },
      {
        exact: true,
        path: '/app/vod/watch/:id?',
        component: lazy(() => import('src/views/vod/VideoPlayerView'))
      },
      {
        exact: true,
        path: '/app/vod/:path*',
        component: lazy(() => import('src/views/vod/HomeView'))
      },
      {
        exact: true,
        path: '/app/channelcreator',
        guard: StorageGuard,
        component: lazy(() => import('src/views/vod/VodView'))
      },
      {
        exact: true,
        path: '/app/channelcreator/add',
        guard: StorageGuard,
        component: lazy(() => import('src/views/vod/VideoAddView'))
      },
      {
        exact: true,
        path: '/app/channelcreator/edit',
        component: lazy(() => import('src/views/vod/VideoEditView'))
      },
      {
        exact: true,
        path: '/app/channelcreator/watch',
        component: lazy(() => import('src/views/vod/VideoPlayerView'))
      },
      {
        exact: true,
        path: '/app/channelShare/accept/:inviteId',
        component: lazy(() => import('src/views/vod/ChannelListView/AcceptChannelInvite'))
      },
      {
        exact: true,
        path: '/app/video-player',
        component: lazy(() => import('src/views/videoPlayer'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to={isFireside() ? "/app/channelcreator" : "/app/account"} />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
