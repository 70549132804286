import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import RegisterView from 'src/views/auth/RegisterView';
import {
  Box,
  Dialog,
  makeStyles
} from '@material-ui/core';
import TopBar from 'src/layouts/DashboardLayout/TopBar';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoginView from 'src/views/auth/LoginView';


const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 64,
    height: '100vh'
  }
}));


const TemporaryAuthGuard = ({ children }) => {
  const classes = useStyles();
  const { isAuthenticated, isAnonymous, signInAnonymously } = useAuth();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 120000);
  });

  if (!isAuthenticated && !isAnonymous) {
    signInAnonymously();
    return (<></>);
  } else if (isAnonymous) {
    return (
      <>
      <Dialog open={open}>
        <RegisterView query={'?redirect=' + encodeURIComponent('/app/player' + location.search)} message='Register for Stremium to Continue Watching' />
      </Dialog>
      <TopBar />
      <Box className={classes.wrapper} >
        {children}
      </Box>
      </>
    );
  } else {
    return (
      <DashboardLayout>
        {children}
      </DashboardLayout>
    );
  }


};

TemporaryAuthGuard.propTypes = {
  children: PropTypes.node
};

export default TemporaryAuthGuard;
